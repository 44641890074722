.index {
    .menu {
        display: flex;
        flex-direction: column;
        width: 500px;
    
        margin: 10vw auto;
        a {
            margin: 10px;
            text-decoration: none;
            font-size: 25px;
        }
    }
}