@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Anton);
@import url(https://fonts.googleapis.com/css?family=Audiowide);
@import url(https://fonts.googleapis.com/css?family=Staatliches);
@import url(https://fonts.googleapis.com/css?family=Sansita);
@import url(https://fonts.googleapis.com/css?family=Racing+Sans+One);
@import url(https://fonts.googleapis.com/css?family=Squada+One);
@import url(https://fonts.googleapis.com/css?family=Francois+One);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
@import url(https://fonts.googleapis.com/css?family=Rubik);
@import url(https://fonts.googleapis.com/css?family=Baloo+Bhai);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Germania+One);
@import url(https://fonts.googleapis.com/css?family=Goblin+One);
@import url(https://fonts.googleapis.com/css?family=Quantico);
@import url(https://fonts.googleapis.com/css?family=Ramabhadra);
@import url(https://fonts.googleapis.com/css?family=Kreon);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
@import url(https://fonts.googleapis.com/css?family=Overpass);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Condensed);
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Arial", sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.index .menu {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 10vw auto; }
  .index .menu a {
    margin: 10px;
    text-decoration: none;
    font-size: 25px; }

.existing-setups {
  color: white;
  width: 800px;
  margin: 40px auto 10px auto; }
  .existing-setups > div:first-of-type {
    font-size: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between; }
    .existing-setups > div:first-of-type .remove-all {
      text-align: right;
      color: white;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 2px 14px 1px 17px;
      border-radius: 15px;
      border: 1px solid #d55;
      background-color: #ce1100;
      cursor: pointer;
      box-sizing: border-box;
      position: relative; }
      .existing-setups > div:first-of-type .remove-all:hover {
        color: yellow; }
      .existing-setups > div:first-of-type .remove-all:active {
        opacity: 0.5;
        transform: scale(0.94) translate(1px, 1px);
        border: 1px solid #000;
        padding: 2px 14px 1px 17px;
        box-shadow: 1px 1px 2px 2px black;
        box-sizing: border-box;
        overflow: hidden; }
      .existing-setups > div:first-of-type .remove-all:focus {
        outline: none; }
  .existing-setups .load-setup {
    background-color: #555;
    display: flex;
    margin-bottom: 10px;
    border: 1px solid #777;
    position: relative;
    overflow: hidden; }
    .existing-setups .load-setup .opacity-zero {
      opacity: 0; }
    .existing-setups .load-setup .display-none {
      display: none; }
    .existing-setups .load-setup .setup-data {
      padding: 2px 0 2px 4px; }
      .existing-setups .load-setup .setup-data .avalue {
        color: cyan; }
  .existing-setups button {
    margin: 10px;
    font-size: 18px;
    height: calc(100% - 20px);
    border: 1px solid #68d;
    color: white;
    font-weight: bold;
    padding: 2px 10px 0 12px;
    background: linear-gradient(to bottom, #2368b2 0%, #207cca 27%, #2681cc 56%, #0d6fd8 88%);
    border-radius: 10px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .existing-setups button.opacity-zero {
      opacity: 0; }
    .existing-setups button:hover {
      color: yellow;
      background: linear-gradient(to bottom, #115f91 0%, #2177b1 27%, #267bb2 56%, #0d6acd 88%); }
    .existing-setups button:active {
      opacity: 0.5;
      transform: scale(0.94) translate(1px, -1px); }
    .existing-setups button.remove {
      border: 1px solid #d55;
      background: linear-gradient(to bottom, #db0e00 0%, #ce1100 10%, #ff1a00 53%, #ce0600 75%); }
      .existing-setups button.remove:hover {
        color: yellow;
        background: linear-gradient(to bottom, #cb0e00 0%, #be1100 10%, #ef1a00 53%, #be0600 75%); }
    .existing-setups button:focus {
      outline: none; }
  .existing-setups .display-loaded {
    position: relative;
    top: -56px;
    left: 15px;
    color: #5f5;
    font-weight: bold; }

.setup-container {
  overflow: auto;
  height: 100vh;
  background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #282828;
  background-size: 16px 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .setup-container .setup {
    width: 100%;
    margin: 2vw auto; }
    .setup-container .setup .arrow-hidden {
      opacity: 0; }
    .setup-container .setup .setup-form {
      display: inline-block;
      border: 1px solid black;
      border-top-left-radius: 38px;
      border-top-right-radius: 38px;
      box-shadow: 0 -1px 0px 1px black, 0 0 2px 2px black, inset 0 0px 1px 1px black, inset 0 0px 1px 0px black;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background-color: white; }
      .setup-container .setup .setup-form .color-hex-display-container {
        border: 1px solid black;
        overflow: hidden;
        height: 132px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACECAMAAABFwSJAAAAANlBMVEWZmZlmZmZra2uWlpaKioqTk5NwcHCNjY13d3eBgYGQkJCEhIRzc3N6enp+fn58fHyHh4eGhobVl/mmAAADJUlEQVR42u3c3ZKjIBAFYKMoYPP7/i+76+ndKixIUZOZ7LaGvvuu+jiVaVBipsdRZkJ5wC1AADYGAfMKKMYOaOChgH0GMrAyLLBsgGcYwCyQLxtZ4DFijVi3j+XOsSyK8u8iWyCfQK/DnkCMXtcJpef6ktPTS44M9/SSA7CtT//Q+mlXmorSj9djTZ8aS8uMJeivtRylZpQC9g3IJSywOiACiwE8wwNuBSywMYixl400oBtdCVgmd1TcURHwDAsYDRDgGJmhAMVIgGYQwwAW2BmB4ctGATBlVydmnAqd8iPW/WL5o4JCBSAybAkCfKqRGRlIDAJUBGwDAQiNRgxfLtVz65K3xjhdqkteuuN0B9RcjdP5SlN+xLpNrPrDN7c+8q77kQde+8gDf2OZo4JGBSAqwAKeQQ0kIAEmA8oDVMIyYgEdO12N1HE6Yo1Y74pVbpoD4HVr0wz0Ns3KtDbNgC83zbHT1U2NzT4jA2vvFsP81C0Gg4BF9pQXHWvcVXdi/bMHSfvXYoWjbELZGiED1ADVyA1YIJ1gOwhixqm7xJQfsW4QazvKr6gImB2wgGMQQwMJ2BiKoQDtAAJ2hmUYIACr73TdeKlWG5ZTBeje08CwAgbwjAiYcql2e71UJ4YuG6k/jcZSfedYd9pBLG+JRSiLoj5QPdC38f1xOn9lnMaxVI9Y/ztWfcxlViAADmgubL1jrvbhGmDKRrG+850n3ga49jYAqLcBDA/4HfjS5sOpsmuqu65XWHyExrrTmvhRsfjMK6IISA1YRiigTrBASAUigxrIQH7aVU3lM//XL7l/uObGUj1iyYhVL9WOEYCtuVSj9M8+pF5OS3V5zJUAZZ4dc+kTIhAZAfAMW4IAo4BcIjFy3VVLHacj1thBvCmW1P9EoXNL6JQfsW4TS9zuVOheXug4vUCssSZeNJbQ51tCnwaKnvIj1mfHesuZj9QTMqHniUKn/AVijR1EJ5bUb40I/Y7NXaf8iDVitZdqqd87FfotXaFTfsS6QSyh72IIfXNFyjgVOuVHrPvFEvo2p9B3X4VO+QvEGnfVnVhSf+FA5u9B/ALxx/YAOpeCTAAAAABJRU5ErkJggg==); }
      .setup-container .setup .setup-form > div > div {
        padding: 0.4rem; }
      .setup-container .setup .setup-form .joggle-example {
        border: 1px solid black;
        position: absolute;
        top: 178px;
        left: 350px;
        overflow: hidden; }
        .setup-container .setup .setup-form .joggle-example span:nth-child(1) {
          margin-left: 1px;
          position: relative;
          top: 2px; }
        .setup-container .setup .setup-form .joggle-example span:nth-child(2) {
          margin-left: 16px;
          margin-right: 0;
          position: relative;
          left: 2px;
          top: 2px; }
        .setup-container .setup .setup-form .joggle-example img {
          width: 226px;
          height: 80px;
          position: relative;
          top: 4px; }
      .setup-container .setup .setup-form .title {
        font-size: 1.6rem;
        padding: 0.29rem 0.4rem 0.35rem 0.4rem;
        background-color: #000;
        color: white;
        text-align: center;
        margin-bottom: 0.5rem;
        border-top-left-radius: 38px;
        border-top-right-radius: 38px;
        box-shadow: 0 -1px 0px 1px black, inset 0 0px 1px 1px black, inset 0 0px 1px 0px black;
        border-bottom: 1px solid #000; }
      .setup-container .setup .setup-form .form-area {
        padding: 0.7rem 1rem 0.8rem 1rem;
        font-size: 1.4rem; }
        .setup-container .setup .setup-form .form-area .colors-title {
          display: flex;
          letter-spacing: 1px;
          font-weight: bold; }
          .setup-container .setup .setup-form .form-area .colors-title span:nth-child(1) {
            color: #F00; }
          .setup-container .setup .setup-form .form-area .colors-title span:nth-child(2) {
            color: #0C0; }
          .setup-container .setup .setup-form .form-area .colors-title span:nth-child(3) {
            color: #25F; }
          .setup-container .setup .setup-form .form-area .colors-title span:nth-child(4) {
            color: #f80; }
          .setup-container .setup .setup-form .form-area .colors-title span:nth-child(5) {
            color: #3af; }
          .setup-container .setup .setup-form .form-area .colors-title span:nth-child(6) {
            color: #8a4; }
          .setup-container .setup .setup-form .form-area .colors-title span.underline {
            position: relative;
            top: 17px;
            width: 100%;
            height: 3px;
            background: linear-gradient(to right, orange, yellow, green, cyan, blue, violet);
            /* Standard syntax (must be last) */ }
        .setup-container .setup .setup-form .form-area .colors {
          position: relative;
          display: flex;
          justify-content: space-between; }
          .setup-container .setup .setup-form .form-area .colors .opacity-title {
            position: absolute;
            top: 10px;
            left: 506px;
            font-size: 21px; }
          .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection {
            display: flex; }
            .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection .color-title {
              font-size: 20px;
              width: 90px;
              margin-right: 1px;
              text-align: right;
              position: relative;
              top: 2px; }
            .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection .arrow-hidden {
              width: 30px;
              height: 30px; }
            .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection .display-arrow {
              display: flex;
              border-top: 4px solid black;
              width: 30px;
              height: 30px;
              position: relative;
              top: 16px;
              left: 3px; }
              .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection .display-arrow span {
                position: absolute;
                top: -14px;
                left: -7px;
                display: block;
                width: 20px;
                height: 21px;
                color: green;
                border-top: 4px solid black;
                border-right: 4px solid black;
                transform: rotate(45deg) skew(-10deg, -10deg); }
            .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection input[type="text"] {
              font-size: 17px;
              padding: 1px 3px;
              width: 85px;
              border: 1px solid black;
              margin-left: 0.5rem;
              margin-bottom: 3px; }
              .setup-container .setup .setup-form .form-area .colors .color-selections .color-selection input[type="text"].invalid {
                background-color: #fdd;
                border: 1px solid red !important; }
          .setup-container .setup .setup-form .form-area .colors .opacity-settings {
            padding-top: 14px; }
            .setup-container .setup .setup-form .form-area .colors .opacity-settings .opacity {
              height: 34px; }
              .setup-container .setup .setup-form .form-area .colors .opacity-settings .opacity input[type="range"] {
                width: 60px;
                height: 23px;
                margin-left: 6px;
                box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1); }
              .setup-container .setup .setup-form .form-area .colors .opacity-settings .opacity span {
                display: inline-block;
                width: 40px;
                font-size: 18px;
                text-align: right;
                position: relative;
                left: -8px;
                top: -4px; }
          .setup-container .setup .setup-form .form-area .colors div {
            margin: auto; }
          .setup-container .setup .setup-form .form-area .colors input[type="text"] {
            margin-top: 8px;
            padding: 0 5px;
            width: 100px;
            border: 1px solid black;
            letter-spacing: 0.5px; }
        .setup-container .setup .setup-form .form-area .orientation {
          display: flex; }
        .setup-container .setup .setup-form .form-area .position {
          display: flex; }
          .setup-container .setup .setup-form .form-area .position .control-position {
            margin-left: 10px; }
            .setup-container .setup .setup-form .form-area .position .control-position input[type="range"] {
              box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1); }
            .setup-container .setup .setup-form .form-area .position .control-position span {
              position: relative;
              top: -3px; }
        .setup-container .setup .setup-form .form-area input[type="number"], .setup-container .setup .setup-form .form-area input[type="text"] {
          font-size: 1.4rem;
          padding: 0 5px;
          width: 80px;
          border: 1px solid black;
          margin-left: 0.5rem; }
        .setup-container .setup .setup-form .form-area input[type="radio"] {
          width: 20px;
          height: 20px;
          position: relative;
          top: 3px;
          margin-left: 1rem; }
        .setup-container .setup .setup-form .form-area .hexCenterDotSize input[type="number"] {
          width: 80px; }
        .setup-container .setup .setup-form .form-area .hexNumberFontSize input[type="number"] {
          width: 60px; }
        .setup-container .setup .setup-form .form-area .hex-line-width input[type="text"] {
          width: 60px; }
        .setup-container .setup .setup-form .form-area span {
          margin-left: 0.5rem;
          font-size: 1.2rem; }
        .setup-container .setup .setup-form .form-area .action-buttons {
          display: flex;
          justify-content: space-around;
          border-top: 1px solid #aaa;
          position: relative;
          padding: 14px 110px 9px 110px;
          top: 10px; }
          .setup-container .setup .setup-form .form-area .action-buttons button {
            padding: 8px 14px 7px 14px;
            font-size: 20px;
            border-radius: 10px;
            box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
            color: white;
            font-weight: bold;
            cursor: pointer; }
            .setup-container .setup .setup-form .form-area .action-buttons button.submit {
              border: 1px solid #68d;
              background: linear-gradient(to bottom, #2368b2 0%, #207cca 27%, #2681cc 56%, #0d6fd8 88%); }
            .setup-container .setup .setup-form .form-area .action-buttons button.reset {
              border: 1px solid #f62;
              background: linear-gradient(to bottom, #FF4500 0%, #FC6520 27%, #FC6520 56%, #FF4500 88%); }
              .setup-container .setup .setup-form .form-area .action-buttons button.reset:hover {
                color: yellow;
                background: linear-gradient(to bottom, #eF4500 0%, #eC6520 27%, #eC6520 56%, #eF4500 88%); }
            .setup-container .setup .setup-form .form-area .action-buttons button:hover {
              color: yellow;
              background: linear-gradient(to bottom, #115f91 0%, #2177b1 27%, #267bb2 56%, #0d6acd 88%); }
            .setup-container .setup .setup-form .form-area .action-buttons button:active {
              opacity: 0.8;
              transform: scale(0.97) translate(1px, -1px); }
              .setup-container .setup .setup-form .form-area .action-buttons button:active.reset {
                background: linear-gradient(to bottom, #bF2500 0%, #bC6520 27%, #bC4520 56%, #bF2500 88%);
                transform: scale(0.99) translate(0, 0.01rem);
                box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4), inset 1px 1px 1px 2px rgba(0, 0, 0, 0.5), inset -1px -1px 1px 2px rgba(0, 0, 0, 0.7);
                text-shadow: 0 0 2px yellow; }
            .setup-container .setup .setup-form .form-area .action-buttons button.remove {
              border: 1px solid #d55;
              background: linear-gradient(to bottom, #db0e00 0%, #ce1100 10%, #ff1a00 53%, #ce0600 75%); }
              .setup-container .setup .setup-form .form-area .action-buttons button.remove:hover {
                color: yellow;
                background: linear-gradient(to bottom, #cb0e00 0%, #be1100 10%, #ef1a00 53%, #be0600 75%); }
            .setup-container .setup .setup-form .form-area .action-buttons button:focus {
              outline: none; }
        .setup-container .setup .setup-form .form-area .invalid input[type="number"], .setup-container .setup .setup-form .form-area .invalid input[type="text"] {
          background-color: #fdd;
          border: 1px solid red !important; }

button::-moz-focus-inner {
  border: 0; }

.hexmap {
  position: relative; }
  .hexmap .svg-container {
    position: absolute;
    top: 0;
    left: 0; }

#thesvg {
  text-decoration: none !important; }

.counter-design {
  position: relative;
  background-color: #00ffff;
  width: 100%;
  padding-top: 100%; }
  .counter-design .display-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.page-control {
  background-color: black; }
  .page-control #page_control_svg {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.tab-manager .tabName-tabs {
  display: flex;
  background-color: #015; }
  .tab-manager .tabName-tabs .tabName-tab {
    margin-top: 2px;
    border-top: 1px solid black;
    border-right: 1px solid black;
    font-size: 87%;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
    background-color: #666;
    color: #eee;
    cursor: pointer; }
    .tab-manager .tabName-tabs .tabName-tab:not(:first-child) {
      border-left: 1px solid black; }
    .tab-manager .tabName-tabs .tabName-tab.active {
      background-color: #f5f5f5;
      color: black; }
    .tab-manager .tabName-tabs .tabName-tab span {
      margin: 0 0.5rem 0 0.5rem; }

.layer-drag-manager {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto; }
  .layer-drag-manager .layer-drag-manager-layers {
    margin-top: 5%;
    border: 1px solid gray;
    background-color: gray; }
    .layer-drag-manager .layer-drag-manager-layers .header-names {
      display: flex;
      font-size: 80%;
      background-color: #029;
      color: white; }
      .layer-drag-manager .layer-drag-manager-layers .header-names .header-name-eye {
        width: 50px; }
      .layer-drag-manager .layer-drag-manager-layers .header-names .header-name-name {
        width: 150px; }
      .layer-drag-manager .layer-drag-manager-layers .header-names .header-name-elements {
        width: 140px; }
      .layer-drag-manager .layer-drag-manager-layers .header-names .header-name-remove {
        width: 100px;
        background-color: #fda; }
    .layer-drag-manager .layer-drag-manager-layers .drop-slot {
      height: 3px;
      background-color: black;
      color: white;
      transition-duration: 100ms;
      width: 100%; }
      .layer-drag-manager .layer-drag-manager-layers .drop-slot.is-over {
        height: 16px;
        background-color: red; }
    .layer-drag-manager .layer-drag-manager-layers .under-drop-slot {
      height: 1px;
      background-color: black;
      width: 100%; }
    .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer {
      display: flex;
      background-color: white;
      margin-top: 1px;
      flex-wrap: wrap; }
      .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-eye {
        width: 30px;
        height: 20px;
        text-align: center;
        background-color: #cdf;
        cursor: pointer; }
        .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-eye.hidden {
          background-color: #444; }
      .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-drag-manager-layer-name {
        padding-top: 1px;
        margin-left: 4px;
        flex-basis: calc(100% - 35px);
        white-space: nowrap;
        flex-shrink: 0;
        vertical-align: baseline;
        display: flex;
        justify-content: space-between; }
        .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-drag-manager-layer-name span {
          margin: 0 4px 0 4px;
          cursor: pointer; }
      .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-elements {
        position: relative;
        top: 1px;
        flex-basis: 48%;
        white-space: nowrap;
        flex-shrink: 0;
        border-left: 1px solid gray;
        padding-left: 4px; }
        .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-elements span:after {
          content: ","; }
        .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-elements span:last-child:after {
          content: ""; }
      .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-remove {
        border-left: 1px solid gray;
        padding-left: 4px;
        margin-left: auto;
        flex-basis: 14px; }
        .layer-drag-manager .layer-drag-manager-layers .layer-drag-manager-layer .layer-remove span {
          display: inline-block;
          font-family: "Rubik";
          font-size: 15px;
          padding-top: 2px;
          color: red;
          transform: scaleX(1.5);
          font-weight: bold; }

.layer .inputs-flex div .button-radio-svg {
  border: 1px solid black;
  display: block;
  box-sizing: border-box;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
  background: linear-gradient(to bottom, #e5f2f6 0%, #eef5f7 49%, #e5f2f6 100%);
  border-radius: 3%; }
  @media screen and (max-width: 1200px) {
    .layer .inputs-flex div .button-radio-svg {
      transform: scale(0.9);
      padding: 0;
      margin: 0; } }
  .layer .inputs-flex div .button-radio-svg .svg-container {
    display: block;
    height: 50px; }
  .layer .inputs-flex div .button-radio-svg .table-type {
    display: table;
    width: 90px;
    height: 35px; }
    .layer .inputs-flex div .button-radio-svg .table-type .text-label {
      text-align: center;
      display: table-cell;
      vertical-align: middle; }
      .layer .inputs-flex div .button-radio-svg .table-type .text-label.shrink {
        font-size: 75%;
        letter-spacing: 0;
        line-height: 0.9rem; }
      .layer .inputs-flex div .button-radio-svg .table-type .text-label.lt10 {
        font-size: 95%;
        position: relative;
        top: 1px; }
      .layer .inputs-flex div .button-radio-svg .table-type .text-label.normal-line-break {
        position: relative;
        top: -2px;
        font-size: 89%;
        line-height: 86%; }
      .layer .inputs-flex div .button-radio-svg .table-type .text-label.eightthree {
        font-size: 83%;
        line-height: 90%; }
      .layer .inputs-flex div .button-radio-svg .table-type .text-label.lt-line-break {
        font-size: 85%;
        line-height: 90%; }
      .layer .inputs-flex div .button-radio-svg .table-type .text-label.special-line-height {
        color: black;
        line-height: 18px;
        font-size: 92%;
        width: 30px;
        position: relative;
        top: -4px; }
  .layer .inputs-flex div .button-radio-svg.selected {
    box-sizing: border-box;
    border-left: 1px solid #9ac;
    border-top: 1px solid #9ac;
    border-right: 1px solid rgba(155, 155, 155, 0.3);
    border-bottom: 1px solid rgba(155, 155, 155, 0.3);
    box-shadow: -1px -1px 1px 1px black;
    background: #eff;
    color: black;
    text-shadow: 0 0 1px rgba(0, 0, 155, 0.3); }

.layer {
  background-color: white;
  color: black;
  font-size: 95%;
  border: 2px solid #000;
  border-top: 5px solid #000; }
  .layer input:focus,
  .layer select:focus,
  .layer textarea:focus,
  .layer button:focus {
    outline: none; }
  .layer.indicate-layer {
    background-color: #99aaff; }
    .layer.indicate-layer .layer-header {
      background-color: #3333ff; }
  .layer .inputs-flex {
    display: flex;
    flex-direction: column;
    margin: 0 0 0.6% 0;
    padding: 1.1% 1.3% 0.1% 1.3%;
    font-size: 90%;
    flex-wrap: nowrap; }
    .layer .inputs-flex div {
      display: flex;
      padding: 0.35% 0.1% 0.1% 0;
      flex-wrap: nowrap; }
      .layer .inputs-flex div .input-container {
        padding: 0.35% 0.1% 0.1% 3%;
        cursor: default; }
        @media screen and (max-width: 1200px) {
          .layer .inputs-flex div .input-container {
            padding: 0.15% 0.05% 0.05% 0; } }
        .layer .inputs-flex div .input-container label {
          cursor: pointer; }
        .layer .inputs-flex div .input-container span:first-child {
          margin-right: 3px; }
        .layer .inputs-flex div .input-container input[type="text"] {
          cursor: text; }
          .layer .inputs-flex div .input-container input[type="text"].color-focused {
            box-shadow: 0 0 3px 1px red;
            border: 1px solid red; }
        .layer .inputs-flex div .input-container input[type="radio"] {
          cursor: pointer; }
        .layer .inputs-flex div .input-container input[type="checkbox"] {
          cursor: pointer;
          position: relative;
          top: 0px; }

@-moz-document url-prefix() {
  .layer .inputs-flex div .input-container input[type="checkbox"] {
    transform: scale(1.5);
    position: relative;
    top: 20%; } }
      .layer .inputs-flex div .input-container:first-child {
        padding: 0.35% 0.1% 0.1% 0;
        flex-basis: initial; }
        @media screen and (max-width: 1200px) {
          .layer .inputs-flex div .input-container:first-child {
            padding: 0.15% 0.05% 0.05% 0; } }
  .layer .layer-header {
    background-color: #001155;
    color: white;
    font-size: 80%;
    padding: 1px 1px 2px 6px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space: nowrap; }
    .layer .layer-header .indicate-layer {
      background-color: yellow;
      color: black;
      top: -1px; }
    .layer .layer-header .layer-contents-hidden {
      opacity: 0.6; }
    .layer .layer-header .contents-toggle {
      display: inline-block;
      text-align: center;
      width: 24px;
      margin-right: 10px;
      height: 23px;
      font-size: 20px;
      font-family: "Goblin One";
      font-weight: bold;
      background-color: #004a99;
      cursor: pointer;
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      -webkit-user-select: none;
              user-select: none; }
      .layer .layer-header .contents-toggle.toggle2 {
        background-color: #444;
        position: relative;
        width: 23px;
        height: 18px;
        padding-right: 1px;
        padding-bottom: 4px; }
    .layer .layer-header.edit {
      justify-content: flex-start; }
      .layer .layer-header.edit button {
        margin-left: 30px; }
    .layer .layer-header .smaller {
      height: 20px;
      margin: 0 10px 0px 0;
      position: relative;
      top: -2px; }
    .layer .layer-header .layer-type-text {
      color: #aaa;
      margin-right: 15px;
      position: relative;
      top: -2px;
      font-size: 80%; }


.counter-row {
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  height: 105px;
  border-bottom: 1px solid black; }
  .counter-row > div:first-child {
    padding: 9px 0 0px 12px;
    width: 100px;
    background: repeating-linear-gradient(-45deg, #cfcfcf, #cfcfcf 1px, #cfcfcf 1px, #e0e0e0 4px); }
  .counter-row > div:nth-child(2) {
    margin-top: 4px;
    margin-left: 12px;
    height: 90px; }
    .counter-row > div:nth-child(2) > div:nth-child(1) {
      margin-top: 10px; }
    .counter-row > div:nth-child(2) > div:nth-child(2) {
      margin-top: 10px; }

.counters {
  height: calc(100vh - 900px);
  overflow-y: auto; }
  @media screen and (max-width: 2242px) {
    .counters {
      height: 525px; } }

.counter-loaded-saved-info {
  background: #001155;
  font-size: 70%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #aaa;
  padding: 2px 0 1px 0; }
  .counter-loaded-saved-info .loaded-saved {
    color: white;
    text-transform: capitalize;
    font-size: 110%; }
  .counter-loaded-saved-info .data-part {
    color: yellow;
    text-transform: none; }
    .counter-loaded-saved-info .data-part.date {
      position: relative;
      top: 1px; }

.log {
  padding: 4px 2px 0 7px;
  font-size: 80%;
  overflow-y: auto;
  height: calc(100vh - 790px);
  max-height: 800px; }
  @media screen and (max-width: 2242px) {
    .log {
      height: 520px; } }
  .log div {
    display: flex; }
    .log div span:nth-child(1) {
      color: red;
      font-size: 90%;
      position: relative;
      top: 2px;
      white-space: nowrap; }
    .log div span:nth-child(2) {
      color: blue;
      margin-left: 9px; }

.database-error {
  padding: 40px;
  font-size: 17px; }
  .database-error span {
    color: red; }

.snap-counter {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  box-sizing: border-box;
  overflow: auto;
  height: 100vh;
  background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #282828;
  background-size: 16px 16px; }
  .snap-counter input[type="text"] {
    cursor: text; }
  @media screen and (max-width: 2241px) {
    .snap-counter {
      flex-wrap: wrap; } }
  @media screen and (max-width: 640px) {
    .snap-counter {
      font-size: 18px; } }
  @media screen and (min-width: 1200px) {
    .snap-counter {
      font-size: 24px; } }
  .snap-counter .nowrap {
    white-space: nowrap; }
  .snap-counter .page-layout {
    background-color: black;
    color: black;
    width: 808px;
    margin: 20px 5px 0 1%;
    position: relative; }
    @media screen and (max-width: 2242px) {
      .snap-counter .page-layout {
        margin: 20px auto 0 auto;
        width: 100vw;
        max-width: 1400px; } }
    .snap-counter .page-layout .page-controls {
      background-color: #efefff; }
      .snap-counter .page-layout .page-controls .page-actions {
        display: flex;
        background-image: linear-gradient(180deg, #e7eff9 0%, #cfd6e6 74%); }
        .snap-counter .page-layout .page-controls .page-actions .loaded-sheet-from-file {
          font-size: 80%;
          margin-left: 2%;
          position: relative;
          top: 3px; }
          .snap-counter .page-layout .page-controls .page-actions .loaded-sheet-from-file span:nth-child(1) {
            margin-left: 4px;
            color: blue;
            font-size: 110%; }
          .snap-counter .page-layout .page-controls .page-actions .loaded-sheet-from-file span:nth-child(2) {
            font-size: 80%;
            color: #555;
            margin-left: 10px;
            white-space: nowrap; }
        .snap-counter .page-layout .page-controls .page-actions div {
          margin: 6px 10px 5px 10px; }
        .snap-counter .page-layout .page-controls .page-actions .export-to-file {
          margin-left: 27px;
          border: 1px solid #aaa;
          padding: 2px 5px 0 6px;
          box-shadow: inset 0 0 2px 2px rgba(40, 40, 40, 0.1);
          font-size: 90%;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
          background-image: linear-gradient(180deg, #ccd2e2 0%, #e9f5fa 10%, #ccd2e2 100%); }
          .snap-counter .page-layout .page-controls .page-actions .export-to-file input[type="checkbox"] {
            transform: scale(1.6);
            position: relative;
            top: -1px;
            margin-right: 7px; }
        .snap-counter .page-layout .page-controls .page-actions .load-from-file-error {
          color: red;
          font-size: 80%;
          position: relative;
          top: 6px;
          font-weight: bold; }
        .snap-counter .page-layout .page-controls .page-actions .load-from-file-div {
          width: 150px;
          display: flex;
          position: relative;
          left: 20px;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
          .snap-counter .page-layout .page-controls .page-actions .load-from-file-div input[type="file"] {
            outline-width: 0;
            opacity: 0; }
          .snap-counter .page-layout .page-controls .page-actions .load-from-file-div .momentary-button {
            position: absolute;
            pointer-events: none;
            top: 2px; }
      .snap-counter .page-layout .page-controls .page-settings {
        font-size: 88%; }
        .snap-counter .page-layout .page-controls .page-settings .page-settings-row {
          padding: 1px 1px 1px 3px;
          display: flex;
          flex-wrap: wrap;
          background-color: #efefff; }
          .snap-counter .page-layout .page-controls .page-settings .page-settings-row:nth-child(2) {
            padding-top: 3px;
            padding-bottom: 2px;
            background-color: #ffefef;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            font-size: 88%; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row:nth-child(2) .marginp span {
              margin-right: 4px; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row:nth-child(2) .marginp:nth-child(2) span:first-child {
              margin-left: 14px; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row:nth-child(2) input[type="text"] {
              margin-left: 1px; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row:nth-child(2) .percent-symbol {
              color: #555; }
          .snap-counter .page-layout .page-controls .page-settings .page-settings-row div {
            white-space: nowrap; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row div:first-child {
              margin-left: 5px; }
          .snap-counter .page-layout .page-controls .page-settings .page-settings-row .label-wrapped-radio {
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.05);
            font-size: 90%;
            margin: 0 3px 0 3px;
            padding: 1px 10px 1px 0;
            white-space: nowrap; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row .label-wrapped-radio input[type="radio"] {
              margin-left: 10px;
              margin-right: 5px; }
            .snap-counter .page-layout .page-controls .page-settings .page-settings-row .label-wrapped-radio span {
              font-size: 88%;
              margin-left: 3px;
              color: #333;
              position: relative;
              top: -1px; }
  .snap-counter .design-wrapper {
    width: 100%;
    min-width: 856px;
    max-width: 1400px;
    margin-top: 20px;
    padding: 0.1%;
    border-top-left-radius: 1.8%;
    border-top-right-radius: 1.8%; }
    .snap-counter .design-wrapper .title {
      text-align: center;
      font-size: 100%;
      padding: 0.5% 0 0.5% 0;
      color: white;
      background-color: black;
      border-top-left-radius: 34px;
      border-top-right-radius: 34px;
      box-sizing: border-box;
      box-shadow: 0 0 1px 1px black; }
      .snap-counter .design-wrapper .title span {
        color: #f11;
        font-size: 80%;
        margin-left: 20px; }
    .snap-counter .design-wrapper .design-container {
      position: relative;
      display: flex;
      flex-direction: horizontal;
      width: 100%;
      min-width: 856px;
      margin: auto; }
      .snap-counter .design-wrapper .design-container.disabled {
        pointer-events: none;
        opacity: 0.2;
        -webkit-filter: brightness(50%);
                filter: brightness(50%); }
      .snap-counter .design-wrapper .design-container .settings {
        width: 56%;
        height: 95vh;
        min-height: 1103px;
        max-height: 1600px;
        overflow-y: scroll;
        border-right: 1px solid black;
        box-sizing: border-box; }
        @media screen and (max-width: 2242px) {
          .snap-counter .design-wrapper .design-container .settings {
            height: 1235px; } }
        .snap-counter .design-wrapper .design-container .settings .setting {
          margin: 0 0 0.6% 0;
          padding: 0.8% 1.3% 1.3% 1.3%; }
          .snap-counter .design-wrapper .design-container .settings .setting .setting-title-with-current-state {
            font-size: 85%; }
            .snap-counter .design-wrapper .design-container .settings .setting .setting-title-with-current-state span {
              margin-left: 1%;
              color: blue;
              text-shadow: 0 0 1px blue; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-identifier {
            background-color: #ffd5f4; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-size {
            background-color: #eaeeff; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-rounding {
            background-color: #eef5d5; }
          .snap-counter .design-wrapper .design-container .settings .setting.unit-type {
            background-color: #f5faf5; }
          .snap-counter .design-wrapper .design-container .settings .setting.unit-size {
            background-color: #ffeaea; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-background-color {
            background-color: #e9fae0; }
          .snap-counter .design-wrapper .design-container .settings .setting.factors-separator {
            background-color: #ffffcc; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-combat-factor {
            background-color: #ffeedd; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-defense-factor {
            background-color: #ddeeff; }
          .snap-counter .design-wrapper .design-container .settings .setting.counter-movement-factor {
            background-color: #e4ffe4; }
          .snap-counter .design-wrapper .design-container .settings .setting.extra {
            background-color: #cff5f5; }
          .snap-counter .design-wrapper .design-container .settings .setting.add-new-layer {
            background-color: #feb;
            border: 2px solid black; }
            .snap-counter .design-wrapper .design-container .settings .setting.add-new-layer .already-exists {
              font-size: 85%;
              color: red;
              margin-left: 1rem; }
          .snap-counter .design-wrapper .design-container .settings .setting .settings-flexwrap-row {
            display: flex;
            flex-wrap: wrap;
            font-size: 85%; }
            .snap-counter .design-wrapper .design-container .settings .setting .settings-flexwrap-row > div {
              padding-top: 1%;
              padding-bottom: 1%;
              white-space: nowrap;
              margin-right: 3%; }
              .snap-counter .design-wrapper .design-container .settings .setting .settings-flexwrap-row > div span {
                vertical-align: text-bottom;
                white-space: nowrap;
                line-height: 85%; }
              .snap-counter .design-wrapper .design-container .settings .setting .settings-flexwrap-row > div label {
                background: rgba(255, 255, 255, 0.2);
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin-right: 0.1rem;
                margin-left: 0.1rem;
                padding: 0 0.3rem 0 0; }
                .snap-counter .design-wrapper .design-container .settings .setting .settings-flexwrap-row > div label input[type="radio"] {
                  position: relative;
                  left: -7px; }
          .snap-counter .design-wrapper .design-container .settings .setting .settings-flexwrap-row:first-of-type div {
            padding-top: 0.5%; }
      .snap-counter .design-wrapper .design-container .right-side {
        position: relative;
        width: 44%;
        background: #f5f5f5; }
        .snap-counter .design-wrapper .design-container .right-side .svg-container {
          position: relative;
          background-color: #00ffff;
          width: 100%;
          padding-top: 100%; }
          .snap-counter .design-wrapper .design-container .right-side .svg-container .display-svg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; }
        .snap-counter .design-wrapper .design-container .right-side .color-picker-container {
          width: 100%;
          padding-top: 18px;
          text-align: center; }
          .snap-counter .design-wrapper .design-container .right-side .color-picker-container #colorPickerCanvas {
            width: 90%;
            background-color: #ff5500;
            border: 1px solid black;
            box-sizing: border-box; }
        .snap-counter .design-wrapper .design-container .right-side .storage-space {
          padding: 0.1% 0 0.3% 1rem;
          background-color: #ddddff;
          font-size: 80%;
          box-shadow: inset 0 -1px 1px 1px rgba(100, 100, 200, 0.01);
          color: #050505; }
          .snap-counter .design-wrapper .design-container .right-side .storage-space span {
            color: blue;
            margin-right: 1rem; }
        .snap-counter .design-wrapper .design-container .right-side .printing-note {
          padding: 1rem 1.2rem 1.2rem 1.2rem;
          font-size: 1rem; }
          .snap-counter .design-wrapper .design-container .right-side .printing-note p {
            margin: 0.25rem 0 0 0; }
        .snap-counter .design-wrapper .design-container .right-side .tabs .tab1 .tab-name {
          background-color: #ddd; }
        .snap-counter .design-wrapper .design-container .right-side .about,
        .snap-counter .design-wrapper .design-container .right-side .help {
          font-size: 80%;
          padding: 0 1.2rem 1.2rem 1.2rem;
          height: 500px;
          overflow-y: auto; }

input[type="radio"] {
  width: 1rem;
  height: 1rem;
  position: relative;
  top: 0.1rem;
  margin-left: 1rem;
  transform: scale(1.2); }
  @media screen and (max-width: 1200px) {
    input[type="radio"] {
      transform: scale(1); } }

.button-radio-row {
  display: flex;
  flex-wrap: wrap; }
  .button-radio-row > div {
    width: 8vw;
    height: 8vw;
    max-width: 80px;
    max-height: 80px;
    min-width: 60px;
    min-height: 60px; }

.select-css {
  font-size: 80%;
  line-height: 1.3;
  padding: 0.1em 0.1em 0.1em 0.4em;
  box-sizing: border-box;
  width: 130px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.3rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5f5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%; }
  .select-css.sheets {
    width: 160px; }
  .select-css.counter {
    width: 180px; }
  .select-css.counter-wide {
    width: 380px; }

.select-css::-ms-expand {
  display: none; }

.select-css:hover {
  border-color: #888; }

.select-css:focus {
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none; }

.select-css option {
  font-weight: normal; }

.checkbox {
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  top: 3px; }
  @media screen and (max-width: 800px) {
    .checkbox {
      width: 1rem;
      height: 1rem; } }

.margin-top-1rem {
  margin-top: 1rem; }

.marginleft10 {
  margin-left: 10px; }

.input-text-2 {
  width: 2.5vw;
  max-width: 2.5rem;
  min-width: 2rem;
  font-size: calc(0.7rem + 0.4vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa; }
  @media screen and (max-width: 800px) {
    .input-text-2 {
      font-size: 13px; } }
  @media screen and (min-width: 1110px) {
    .input-text-2 {
      font-size: 17px; } }
  .input-text-2:focus {
    outline: none;
    /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff; }

.input-text-3 {
  width: 3vw;
  max-width: 3rem;
  min-width: 2rem;
  font-size: calc(0.7rem + 0.5vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa; }
  @media screen and (max-width: 800px) {
    .input-text-3 {
      font-size: 14px; } }
  @media screen and (min-width: 1110px) {
    .input-text-3 {
      font-size: 19px; } }
  .input-text-3:focus {
    outline: none;
    /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff; }

.input-text-4 {
  width: 3.5vw;
  max-width: 3.5rem;
  min-width: 2.5rem;
  font-size: calc(0.7rem + 0.5vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa; }
  @media screen and (max-width: 800px) {
    .input-text-4 {
      font-size: 14px; } }
  @media screen and (min-width: 1110px) {
    .input-text-4 {
      font-size: 19px; } }
  .input-text-4:focus {
    outline: none;
    /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff; }

.margin-right-3 {
  margin-right: 3%; }

.width1 {
  min-width: 10px;
  max-width: 15px;
  width: 1vw; }

.width20 {
  min-width: 20px;
  max-width: 25px;
  width: 1vw; }

.width30 {
  min-width: 35px;
  max-width: 40px;
  width: 2vw; }

.input-text-7 {
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  font-size: 16px;
  width: 80px; }
  @media screen and (min-width: 1110px) {
    .input-text-7 {
      font-size: 18px;
      width: 90px; } }
  .input-text-7:focus {
    outline: none;
    /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff; }

.input-text-12 {
  width: 13vw;
  max-width: 8rem;
  min-width: 5rem;
  font-size: calc(0.7rem + 0.5vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa; }
  @media screen and (max-width: 800px) {
    .input-text-12 {
      font-size: 14px; } }
  @media screen and (min-width: 1110px) {
    .input-text-12 {
      font-size: 19px; } }
  .input-text-12:focus {
    outline: none;
    /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff; }

.design-controls {
  padding: 0.3rem 0 0.3rem 0.3rem;
  min-height: 35px;
  background-color: #e7eff9;
  background-image: linear-gradient(180deg, #e7eff9 0%, #cfd6e6 74%);
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around; }
  .design-controls .momentary-button.cancel {
    position: relative;
    top: 2px;
    margin-left: 0; }
  .design-controls .save-controls {
    display: flex; }
  .design-controls .save-as {
    font-size: 88%;
    margin-right: 1rem; }
  .design-controls.load-counter {
    justify-content: center; }
    .design-controls.load-counter > :nth-child(2) {
      width: 270px; }
    .design-controls.load-counter > :nth-child(2) {
      margin-left: 5px; }
  .design-controls .export-to-file {
    border: 1px solid #aaa;
    padding: 2px 5px 0 6px;
    box-shadow: inset 0 0 2px 2px rgba(40, 40, 40, 0.1);
    font-size: 90%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-image: linear-gradient(180deg, #ccd2e2 0%, #e9f5fa 10%, #ccd2e2 100%); }
    .design-controls .export-to-file input[type="checkbox"] {
      transform: scale(1.6);
      position: relative;
      top: -1px;
      margin-right: 7px; }
  .design-controls .load-from-file-div {
    display: flex;
    position: relative;
    left: 20px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .design-controls .load-from-file-div input[type="file"] {
      outline-width: 0;
      opacity: 0; }
    .design-controls .load-from-file-div .momentary-button {
      position: absolute;
      pointer-events: none;
      top: 2px; }

.two-state-button {
  cursor: pointer;
  color: #ddd;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #aa0f00;
  box-shadow: 0px 0px 1px 2px #780000;
  border-radius: 0.5rem;
  position: relative;
  font-size: 80%;
  height: 27px;
  top: -1px;
  text-shadow: 0.05rem 0.05rem 0rem rgba(255, 255, 255, 0.5); }
  @media screen and (max-width: 1150px) {
    .two-state-button:not(.sheet) {
      font-size: 66%;
      height: 20px;
      top: -1px; } }
  .two-state-button.active {
    background: #ff0f02;
    box-shadow: -0.01rem 0px 1px 2px #960000, 0.01rem 0px 1px 2px #960000;
    text-shadow: 0px 0px 1px yellow;
    transform: scale(0.995);
    border-radius: 0.5rem; }
  .two-state-button:focus {
    outline: 0 !important; }

.momentary-button {
  cursor: pointer;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #0a59ff;
  box-shadow: 0px 0px 1px 2px #0000dc;
  border-radius: 0.5rem;
  position: relative;
  font-size: 80%;
  height: 27px;
  top: -1px;
  white-space: nowrap;
  text-shadow: 0.05rem 0.05rem 0rem rgba(110, 110, 110, 0.5); }
  @media screen and (max-width: 1150px) {
    .momentary-button:not(.sheet) {
      font-size: 66%;
      height: 20px;
      top: -1px; } }
  .momentary-button.negative {
    margin-left: 1rem;
    box-shadow: 0px 0px 1px 2px #783c5a;
    background: #bd596e;
    text-shadow: 0.05rem 0.05rem 0rem rgba(155, 0, 0, 0.5); }
  .momentary-button.cancel {
    margin-left: 1rem;
    box-shadow: 0px 0px 1px 2px black;
    background: #ebeb00;
    color: black;
    text-shadow: 0.05rem 0.05rem 0rem rgba(0, 0, 0, 0.1); }
  .momentary-button:active {
    background: #0559ff;
    box-shadow: -0.01rem 0px 1px 2px #0000dc, 0.01rem 0px 1px 2px #0000dc;
    color: yellow;
    text-shadow: 0px 0px 1px yellow;
    transform: scale(0.995);
    border-radius: 0.5rem;
    height: 27px; }
    .momentary-button:active.negative {
      box-shadow: 0px 0px 1px 2px #783c5a;
      background: #b35564; }
    .momentary-button:active.cancel {
      box-shadow: 0px 0px 1px 2px #78785a;
      background: #433e00; }
  .momentary-button:focus {
    outline: 0 !important; }
  .momentary-button.inactive {
    box-shadow: 0px 0px 1px 2px black;
    background: #737373;
    color: #ddd;
    cursor: default; }

.display-none {
  display: none !important; }

.path-reducer {
  text-align: center;
  margin-top: 1%; }
  .path-reducer .title {
    font-size: 40px;
    font-weight: bold; }
  .path-reducer .centered {
    border: 1px solid black;
    width: 1500px;
    display: inline-block;
    margin: 0 auto; }
    .path-reducer .centered .enter-path textarea {
      width: 90%;
      height: 500px;
      padding: 5px; }
    .path-reducer .centered .action-button {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      font-size: 20px; }
      .path-reducer .centered .action-button div {
        margin-left: 10px; }
      .path-reducer .centered .action-button input[type="text"] {
        width: 30px;
        font-size: 20px; }
      .path-reducer .centered .action-button input[type="submit"], .path-reducer .centered .action-button input[type="button"] {
        margin-left: 15px;
        font-size: 20px; }
    .path-reducer .centered div.text-title {
      margin: 10px 0 6px 10px;
      font-size: 20px;
      text-align: left; }
    .path-reducer .centered .reduced-path {
      text-align: center; }
      .path-reducer .centered .reduced-path .path-area {
        background-color: #eee;
        width: 90%;
        display: inline-block;
        min-height: 300px;
        border: 1px solid black;
        margin-bottom: 15px;
        text-align: left;
        padding: 5px; }

.app {

}





