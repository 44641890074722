.layer .inputs-flex div .button-radio-svg {
  border: 1px solid black;
  display: block;
  box-sizing: border-box;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    transform: scale(0.9);
    padding: 0;
    margin: 0;
  }
  background: linear-gradient(
    to bottom,
    rgba(229, 242, 246, 1) 0%,
    rgba(238, 245, 247, 1) 49%,
    rgba(229, 242, 246, 1) 100%
  );
  border-radius: 3%;
  .svg-container {
    display: block;
    height: 50px;
  }
  .table-type {
    display: table;
    width: 90px;
    height: 35px;
    .text-label {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      &.shrink {
          font-size: 75%;
          letter-spacing: 0;
          line-height: 0.9rem;
      }
      &.lt10 {
        font-size: 95%;
        position: relative;
        top: 1px;
      }
      &.normal-line-break {
        position: relative;
        top: -2px;
        font-size: 89%;
        line-height: 86%;
      }
      &.eightthree {
        font-size: 83%;
        line-height: 90%;
      }
      &.lt-line-break {
        font-size: 85%;
        line-height: 90%;
      }
      &.special-line-height {
          color: black;
          line-height: 18px;
          font-size: 92%;
          width: 30px;
          position: relative;
          top: -4px;
      }
    }
  }
  &.selected {
    box-sizing: border-box;
    border-left: 1px solid #9ac;
    border-top: 1px solid #9ac;
    border-right: 1px solid rgba(155,155,155,0.3);
    border-bottom: 1px solid rgba(155,155,155,0.3);
    box-shadow: -1px -1px 1px 1px black;
    background: #eff;
    color: black;
    text-shadow: 0 0 1px rgba(0, 0, 155, 0.3);
    // position: relative;
    // top: 1px;
    // left: 1px;
  }
}
// .button-radio-svg {
//   user-select: none;
//   width: 100%;
//   height: 100%;
//   margin: 1%;
//   position: relative;
//   cursor: pointer;
//   // display: flex;
//   // flex-direction: column;
//   box-sizing: border-box;
//   border-left: 1px solid #ccc;
//   border-top: 1px solid #ccc;
//   border-right: 1px solid black;
//   border-bottom: 1px solid black;
//   background: linear-gradient(
//     to bottom,
//     rgba(229, 242, 246, 1) 0%,
//     rgba(238, 245, 247, 1) 49%,
//     rgba(229, 242, 246, 1) 100%
//   );
//   border-radius: 3%;
//   color: black;
//   box-shadow: 1px 1px 1px 1px black, 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
//   div:nth-child(1) {
//       position: relative;
//       top: -5%;
//     height: 70%;
//     &.scaleUp {
//       transform: scale(1.2);
//     }
//   }
//   overflow: hidden;
//   .text-wrap {
//     position: relative;
//     top: -11%;
//     width: 100%;
//     display: table;
//     div {
//       text-align: center;
//       font-size: 80%;
//       display: table-cell;
//       vertical-align: middle;
//       width: 100%;
//      // height: calc(28px - (60% - 1em));
//       //line-height: 90%;
//       &.twoline {
//         font-size: 70%;
//         line-height: 80%;
//       }
//       &.twoline2 {
//         font-size: 70%;
//         line-height: 80%;
//       }
//       &.twoline3 {
//         font-size: 65%;
//         line-height: 90%;
//       }
//       &.smaller {
//         font-size: 75%;
//         line-height: 85%;
//         line-height: 150%;
//       }
//       &.smaller2 {
//         font-size: 70%;
//         line-height: 160%;
//       }
//       &.shift-down-1 {
//         position: relative;
//         top: 3px;
//       }
//       &.shift-up-1 {
//         position: relative;
//         top: -2px;
//     }
//     }
//   }
//   &.selected {
//        box-sizing: border-box;
//      //padding: 0.1rem 0.5rem 0.5rem 0.1rem;
//     border-left: 1px solid #88ccff;
//     border-top: 1px solid #88ccff;
//     border-right: 1px solid #ccc;
//     border-bottom: 1px solid #ccc;
//     box-shadow: -1px -1px 1px 1px black, -2px -2px 2px 2px rgba(0, 0, 0, 0.4),
//       inset 0 0 10px 2px rgba(160, 200, 255, 0.1);
//     background: #eff;
//     color: black;
//     text-shadow: 0 0 1px rgba(0,0,155,0.3);
//   }
// }
