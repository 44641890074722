.database-error {
  padding: 40px;
  font-size: 17px;
  span {
    color: red;
  }
}
.snap-counter {
  display: flex;
  margin: 0 auto;
  //flex-wrap: wrap;
  justify-content: center;
  font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  input[type="text"] {
    cursor: text;
  }
  @media screen and (max-width: 2241px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 24px;
  }
  .nowrap {
    white-space: nowrap;
  }
  box-sizing: border-box;
  overflow: auto;
  height: 100vh;
  background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #282828;
  background-size: 16px 16px;
  .page-layout {
    background-color: black;
    color: black;
    width: 808px;
    margin: 20px 5px 0 1%;
    //height: 1178px;
    position: relative;
    @media screen and (max-width: 2242px) {
      margin: 20px auto 0 auto;
      width: 100vw;
      max-width: 1400px;
      //height: 131.1vw;
    }
    .page-controls {
      background-color: #efefff;
      .page-actions {
        display: flex;
        background-image: linear-gradient(180deg, #e7eff9 0%, #cfd6e6 74%);
        .loaded-sheet-from-file {
          font-size: 80%;
          margin-left: 2%;
          position: relative;
          top: 3px;
          span:nth-child(1) {
              margin-left: 4px;
            color: blue;
            font-size: 110%;
          }
          span:nth-child(2) {
            font-size: 80%;
            color: #555;
            margin-left: 10px;
            white-space: nowrap;
          }
        }
        div {
          margin: 6px 10px 5px 10px;
        }
        .export-to-file {
          margin-left: 27px;
          input[type="checkbox"] {
            transform: scale(1.6);
            position: relative;
            top: -1px;
            margin-right: 7px;
          }
          border: 1px solid #aaa;
          padding: 2px 5px 0 6px;
          box-shadow: inset 0 0 2px 2px rgba(40, 40, 40, 0.1);
          font-size: 90%;
          user-select: none;
          background-image: linear-gradient(180deg, #ccd2e2 0%, #e9f5fa 10%, #ccd2e2 100%);
        }
        .load-from-file-error {
          color: red;
          font-size: 80%;
          position: relative;
          top: 6px;
          font-weight: bold;
        }
        .load-from-file-div {
          width: 150px;
          display: flex;
          position: relative;
          left: 20px;
          user-select: none;
          input[type="file"] {
            outline-width: 0;
            opacity: 0;
          }
          .momentary-button {
            position: absolute;
            //opacity: 0.3;
            pointer-events: none;
            //left: 10px;
            top: 2px;
          }
        }
      }
      .page-settings {
        font-size: 88%;

        .page-settings-row {
          &:nth-child(2) {
            padding-top: 3px;
            padding-bottom: 2px;
            background-color: #ffefef;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            font-size: 88%;
            .marginp {
              span {
                margin-right: 4px;
              }
              &:nth-child(2) {
                span:first-child {
                  margin-left: 14px;
                }
              }
            }
            input[type="text"] {
              margin-left: 1px;
            }
            .percent-symbol {
              color: #555;
            }
          }

          padding: 1px 1px 1px 3px;
          display: flex;
          flex-wrap: wrap;
          background-color: #efefff;
          div {
            white-space: nowrap;
            &:first-child {
              margin-left: 5px;
            }
          }
          .label-wrapped-radio {
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.05);
            font-size: 90%;
            margin: 0 3px 0 3px;
            padding: 1px 10px 1px 0;
            white-space: nowrap;
            input[type="radio"] {
              margin-left: 10px;
              margin-right: 5px;
            }
            span {
              font-size: 88%;
              margin-left: 3px;
              color: #333;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
  }
  .design-wrapper {
    width: 100%;
    min-width: 856px;
    max-width: 1400px;
    margin-top: 20px;
    padding: 0.1%;
    border-top-left-radius: 1.8%;
    border-top-right-radius: 1.8%;

    .title {
      text-align: center;
      font-size: 100%;
      padding: 0.5% 0 0.5% 0;
      color: white;
      background-color: black;
      border-top-left-radius: 34px;
      border-top-right-radius: 34px;
      box-sizing: border-box;
      box-shadow: 0 0 1px 1px black;
      span {
          color: #f11;
          font-size: 80%;
          margin-left: 20px;
      }
    }
    .design-container {
      position: relative;
      display: flex;
      flex-direction: horizontal;
      width: 100%;
      min-width: 856px;
      margin: auto;
      &.disabled {
        pointer-events: none;
        opacity: 0.2;
        filter: brightness(50%);
      }

      .settings {
        width: 56%;
        height: 95vh;
        min-height: 1103px;
        max-height: 1600px;
        overflow-y: scroll;
        border-right: 1px solid black;
        box-sizing: border-box;
        @media screen and (max-width: 2242px) {
          height: 1235px;
        }

        .setting {
          margin: 0 0 0.6% 0;
          padding: 0.8% 1.3% 1.3% 1.3%;
          .setting-title-with-current-state {
            font-size: 85%;
            span {
              margin-left: 1%;
              color: blue;
              text-shadow: 0 0 1px blue;
            }
          }
          &.counter-identifier {
            background-color: #ffd5f4;
          }
          &.counter-size {
            background-color: #eaeeff;
          }
          &.counter-rounding {
            background-color: #eef5d5;
          }
          &.unit-type {
            background-color: #f5faf5;
          }
          &.unit-size {
            background-color: #ffeaea;
          }
          &.counter-background-color {
            background-color: #e9fae0;
          }
          &.factors-separator {
            background-color: #ffffcc;
          }
          &.counter-combat-factor {
            background-color: #ffeedd;
          }
          &.counter-defense-factor {
            background-color: #ddeeff;
          }
          &.counter-movement-factor {
            background-color: #e4ffe4;
          }
          &.extra {
            background-color: #cff5f5;
          }
          &.add-new-layer {
            background-color: #feb;
            border: 2px solid black;
            .already-exists {
              font-size: 85%;
              color: red;
              margin-left: 1rem;
            }
          }

          .settings-flexwrap-row {
            display: flex;
            flex-wrap: wrap;
            font-size: 85%;
            > div {
              padding-top: 1%;
              padding-bottom: 1%;
              white-space: nowrap;
              margin-right: 3%;
              span {
                vertical-align: text-bottom;
                white-space: nowrap;
                line-height: 85%;
              }
              label {
                background: rgba(255, 255, 255, 0.2);
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin-right: 0.1rem;
                margin-left: 0.1rem;
                padding: 0 0.3rem 0 0;
                input[type="radio"] {
                  position: relative;
                  left: -7px;
                }
              }
            }
          }
          .settings-flexwrap-row:first-of-type {
            div {
              padding-top: 0.5%;
            }
          }
        }
      }
      .right-side {
        position: relative;
        width: 44%;
        background: #f5f5f5;
        .svg-container {
          position: relative;
          background-color: #00ffff;
          width: 100%;
          padding-top: 100%;
          .display-svg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
        .color-picker-container {
          //margin: 20px;
          // width: calc(100% - 30px);
          width: 100%;
          padding-top: 18px;
          text-align: center;
          #colorPickerCanvas {
            width: 90%;
            //     width: 550px;
            //    height: 268px;
            background-color: #ff5500;
            border: 1px solid black;
            box-sizing: border-box;
          }
        }
        .storage-space {
          padding: 0.1% 0 0.3% 1rem;
          background-color: #ddddff;
          font-size: 80%;
          box-shadow: inset 0 -1px 1px 1px rgba(100, 100, 200, 0.01);
          color: #050505;
          span {
            color: blue;
            margin-right: 1rem;
          }
        }
        .printing-note {
          padding: 1rem 1.2rem 1.2rem 1.2rem;
          font-size: 1rem;
          p {
            margin: 0.25rem 0 0 0;
          }
        }

        .tabs {
          .tab1 {
            .tab-name {
              background-color: #ddd;
            }
          }
        }
        .about,
        .help {
          font-size: 80%;
          padding: 0 1.2rem 1.2rem 1.2rem;
          height: 500px;
          overflow-y: auto;
        }
      }
    }
  }
}

input[type="radio"] {
  width: 1rem;
  height: 1rem;
  position: relative;
  top: 0.1rem;
  margin-left: 1rem;
  transform: scale(1.2);
  @media screen and (max-width: 1200px) {
    transform: scale(1);
  }
}

.button-radio-row {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 8vw;
    height: 8vw;
    max-width: 80px;
    max-height: 80px;
    min-width: 60px;
    min-height: 60px;
  }
}

.select-css {
  font-size: 80%;
  line-height: 1.3;
  padding: 0.1em 0.1em 0.1em 0.4em;
  box-sizing: border-box;
  width: 130px;
  &.sheets {
    width: 160px;
  }
  &.counter {
    width: 180px;
  }
  &.counter-wide {
    width: 380px;
  }
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.3rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5f5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  // border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

.checkbox {
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  top: 3px;
  @media screen and (max-width: 800px) {
    width: 1rem;
    height: 1rem;
  }
}
.margin-top-1rem {
  margin-top: 1rem;
}
.marginleft10 {
  margin-left: 10px;
}
.input-text-2 {
  width: 2.5vw;
  max-width: 2.5rem;
  min-width: 2rem;
  font-size: calc(0.7rem + 0.4vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  @media screen and (max-width: 800px) {
    font-size: 13px;
  }
  @media screen and (min-width: 1110px) {
    font-size: 17px;
  }
  &:focus {
    outline: none; /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff;
  }
}
.input-text-3 {
  width: 3vw;
  max-width: 3rem;
  min-width: 2rem;
  font-size: calc(0.7rem + 0.5vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1110px) {
    font-size: 19px;
  }
  &:focus {
    outline: none; /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff;
  }
}
.input-text-4 {
  width: 3.5vw;
  max-width: 3.5rem;
  min-width: 2.5rem;
  font-size: calc(0.7rem + 0.5vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1110px) {
    font-size: 19px;
  }
  &:focus {
    outline: none; /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff;
  }
}
.margin-right-3 {
  margin-right: 3%;
}

.width1 {
  min-width: 10px;
  max-width: 15px;
  width: 1vw;
}

.width20 {
  min-width: 20px;
  max-width: 25px;
  width: 1vw;
}

.width30 {
  min-width: 35px;
  max-width: 40px;
  width: 2vw;
}

.input-text-7 {
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  font-size: 16px;
  width: 80px;
  @media screen and (min-width: 1110px) {
    font-size: 18px;
    width: 90px;
  }
  &:focus {
    outline: none; /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff;
  }
}

.input-text-12 {
  width: 13vw;
  max-width: 8rem;
  min-width: 5rem;
  font-size: calc(0.7rem + 0.5vw);
  padding: 0.05rem 0.1rem 0 0.2rem;
  box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1110px) {
    font-size: 19px;
  }
  &:focus {
    outline: none; /* Remove default outline and use border or box-shadow */
    box-shadow: inset -0.01rem -0.01rem 2px 2px rgba(110, 110, 210, 0.2);
    background-color: #f0f5ff;
  }
}

.design-controls {
  padding: 0.3rem 0 0.3rem 0.3rem;
  min-height: 35px;
  background-color: #e7eff9;
  background-image: linear-gradient(180deg, #e7eff9 0%, #cfd6e6 74%);
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  .momentary-button.cancel {
    position: relative;
    top: 2px;
    margin-left: 0;
  }
  .save-controls {
    display: flex;
  }
  .save-as {
    font-size: 88%;
    margin-right: 1rem;
  }
  &.load-counter {
    justify-content: center;
    > :nth-child(2) {
      width: 270px;
    }
    > :nth-child(2) {
      margin-left: 5px;
    }
  }
  .export-to-file {
    input[type="checkbox"] {
      transform: scale(1.6);
      position: relative;
      top: -1px;
      margin-right: 7px;
    }
    border: 1px solid #aaa;
    padding: 2px 5px 0 6px;
    box-shadow: inset 0 0 2px 2px rgba(40, 40, 40, 0.1);
    font-size: 90%;
    user-select: none;
    background-image: linear-gradient(180deg, #ccd2e2 0%, #e9f5fa 10%, #ccd2e2 100%);
  }
  .load-from-file-div {
    display: flex;
    position: relative;
    left: 20px;
    user-select: none;
    input[type="file"] {
      outline-width: 0;
      opacity: 0;
    }
    .momentary-button {
      position: absolute;
      //opacity: 0.3;
      pointer-events: none;
      //left: 10px;
      top: 2px;
    }
  }
}

.two-state-button {
  cursor: pointer;
  color: #ddd;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgb(170, 15, 0);
  box-shadow: 0px 0px 1px 2px rgba(120, 0, 0, 1);
  border-radius: 0.5rem;
  position: relative;
  font-size: 80%;
  height: 27px;
  top: -1px;
  text-shadow: 0.05rem 0.05rem 0rem rgba(255, 255, 255, 0.5);
  &:not(.sheet) {
    @media screen and (max-width: 1150px) {
      font-size: 66%;
      height: 20px;
      top: -1px;
    }
  }
  &.active {
    background: rgb(255, 15, 2);
    box-shadow: -0.01rem 0px 1px 2px rgba(150, 0, 0, 1), 0.01rem 0px 1px 2px rgba(150, 0, 0, 1);
    text-shadow: 0px 0px 1px yellow;
    transform: scale(0.995);
    border-radius: 0.5rem;
  }
  &:focus {
    outline: 0 !important;
  }
}

.momentary-button {
  cursor: pointer;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgb(10, 89, 265);
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 220, 1);
  border-radius: 0.5rem;
  position: relative;
  font-size: 80%;
  height: 27px;
  top: -1px;
  white-space: nowrap;
  text-shadow: 0.05rem 0.05rem 0rem rgba(110, 110, 110, 0.5);
  &:not(.sheet) {
    @media screen and (max-width: 1150px) {
      font-size: 66%;
      height: 20px;
      top: -1px;
    }
  }
  &.negative {
    margin-left: 1rem;
    box-shadow: 0px 0px 1px 2px rgba(120, 60, 90, 1);
    background: rgb(189, 89, 110);
    text-shadow: 0.05rem 0.05rem 0rem rgba(155, 0, 0, 0.5);
  }
  &.cancel {
    margin-left: 1rem;
    box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 1);
    background: rgb(235, 235, 0);
    color: black;
    text-shadow: 0.05rem 0.05rem 0rem rgba(0, 0, 0, 0.1);
  }
  &:active {
    background: rgb(5, 89, 275);
    box-shadow: -0.01rem 0px 1px 2px rgba(0, 0, 220, 1), 0.01rem 0px 1px 2px rgba(0, 0, 220, 1);
    color: yellow;
    text-shadow: 0px 0px 1px yellow;
    transform: scale(0.995);
    border-radius: 0.5rem;
    &.negative {
      box-shadow: 0px 0px 1px 2px rgba(120, 60, 90, 1);
      background: rgb(179, 85, 100);
    }
    &.cancel {
      box-shadow: 0px 0px 1px 2px rgba(120, 120, 90, 1);
      background: rgb(67, 62, 0);
    }
    height: 27px;
    // top: -1px;
    // padding: 0 7px 0 9px;
  }
  &:focus {
    outline: 0 !important;
  }
  &.inactive {
    box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 1);
    background: rgb(115, 115, 115);
    color: #ddd;
    cursor: default;
  }
}

.display-none {
  display: none !important;
}
