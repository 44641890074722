.counter-row {
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  height: 105px;
  border-bottom: 1px solid black;
  > div {
    &:first-child {
        padding: 9px 0 0px 12px;
      width: 100px;
      background: repeating-linear-gradient(
  -45deg,
  #cfcfcf,
  #cfcfcf 1px,
  #cfcfcf 1px,
  #e0e0e0 4px
);
    }
    &:nth-child(2) {
      margin-top: 4px;
      margin-left: 12px;
      height: 90px;
      > div:nth-child(1) {
        margin-top: 10px;
      }
      > div:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
