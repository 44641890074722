.log {
    padding: 4px 2px 0 7px;
    font-size: 80%;
    overflow-y: auto;
    height: calc(100vh - 790px);
    max-height: 800px;
    @media screen and (max-width: 2242px) {
        height: 520px;
      }
    div {
        display: flex;
        span:nth-child(1) {
            color: red;
            font-size: 90%;
            position: relative;
            top: 2px;
            white-space: nowrap;
        }
        span:nth-child(2) {
            color: blue;
            margin-left: 9px;
        }
    }
}