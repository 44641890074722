.existing-setups {
  color: white;
  width: 800px;
  margin: 40px auto 10px auto;
  > div:first-of-type {
    font-size: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .remove-all {
      text-align: right;
      color: white;
      background-color: rgba(0, 0, 0, 0.8);
      // margin: 0 3px 0 0;
      padding: 2px 14px 1px 17px;
      border-radius: 15px;
      border: 1px solid #d55;
      background-color: #ce1100;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
      &:hover {
        color: yellow;
      }
      &:active {
        opacity: 0.5;
        transform: scale(0.94) translate(1px, 1px);
        border: 1px solid #000;
        padding: 2px 14px 1px 17px;
        box-shadow: 1px 1px 2px 2px black;
        // margin-bottom: 2px;
        box-sizing: border-box;
        overflow: hidden;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .load-setup {
    background-color: #555;
    display: flex;
    margin-bottom: 10px;
    border: 1px solid #777;
    position: relative;
    overflow: hidden;
    .opacity-zero {
      opacity: 0;
    }

    .display-none {
      display: none;
    }
    .setup-data {
      padding: 2px 0 2px 4px;
      .avalue {
        color: cyan;
      }
    }
  }
  button {
    margin: 10px;
    font-size: 18px;
    height: calc(100% - 20px);
    border: 1px solid #68d;
    color: white;
    font-weight: bold;
    padding: 2px 10px 0 12px;
    background: linear-gradient(to bottom, #2368b2 0%, #207cca 27%, #2681cc 56%, #0d6fd8 88%);
    border-radius: 10px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &.opacity-zero {
      opacity: 0;
    }
    &:hover {
      color: yellow;
      background: linear-gradient(to bottom, #115f91 0%, #2177b1 27%, #267bb2 56%, #0d6acd 88%);
    }
    &:active {
      opacity: 0.5;
      transform: scale(0.94) translate(1px, -1px);
    }
    &.remove {
      border: 1px solid #d55;
      background: linear-gradient(to bottom, #db0e00 0%, #ce1100 10%, #ff1a00 53%, #ce0600 75%);
      &:hover {
        color: yellow;
        background: linear-gradient(to bottom, #cb0e00 0%, #be1100 10%, #ef1a00 53%, #be0600 75%);
      }
    }
    &:focus {
      outline: none;
    }
  }
  .display-loaded {
      position: relative;
      top: -56px;
      left: 15px;
    color: #5f5;
    font-weight: bold;
  }
}
