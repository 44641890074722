.setup-container {
    overflow: auto;
    height: 100vh;
    background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px,
      radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
      radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
    background-color: #282828;
    background-size: 16px 16px;
    user-select: none;
  .setup {
    width: 100%;

    margin: 2vw auto;
    .arrow-hidden {
      opacity: 0;
    }
    .setup-form {
      display: inline-block;
      border: 1px solid black;
      border-top-left-radius: 38px;
      border-top-right-radius: 38px;
      //overflow: hidden;
      box-shadow: 0 -1px 0px 1px black, 0 0 2px 2px black, inset 0 0px 1px 1px black, inset 0 0px 1px 0px black;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      .color-hex-display-container {
        border: 1px solid black;
        overflow: hidden;
        height: 132px;
        background-image: url(../../images/transparent_pattern.png); 
      }
      > div > div {
        padding: 0.4rem;
      }
      .joggle-example {
        border: 1px solid black;
        position: absolute;
        top: 178px;
        left: 350px;
        overflow: hidden;
        span:nth-child(1) {
          margin-left: 1px;
          position: relative;
          top: 2px;
        }
        span:nth-child(2) {
          margin-left: 16px;
          margin-right: 0;
          position: relative;
          left: 2px;
          top: 2px;
        }
        img {
          width: 226px;
          height: 80px;
          position: relative;
          top: 4px;
        }
      }
      .title {
        font-size: 1.6rem;
        padding: 0.29rem 0.4rem 0.35rem 0.4rem;
        background-color: #000;
        color: white;
        text-align: center;
        margin-bottom: 0.5rem;
        border-top-left-radius: 38px;
        border-top-right-radius: 38px;
        box-shadow: 0 -1px 0px 1px black, inset 0 0px 1px 1px black, inset 0 0px 1px 0px black;
        border-bottom: 1px solid #000;
      }
      .form-area {
        padding: 0.7rem 1rem 0.8rem 1rem;
        font-size: 1.4rem;
       
        .colors-title {
            display: flex;
            letter-spacing: 1px;
            font-weight: bold;
            span:nth-child(1) {
                color: #F00;
            }
            span:nth-child(2) {
                color: #0C0;
            }
            span:nth-child(3) {
                color: #25F;
            }
            span:nth-child(4) {
                color: #f80;
            }
            span:nth-child(5) {
                color: #3af;
            }
            span:nth-child(6) {
                color: #8a4;
            }
            span.underline {
                position: relative;
                top: 17px;
                width: 100%;
                height: 3px;
                background: linear-gradient(to right, orange , yellow, green, cyan, blue, violet); /* Standard syntax (must be last) */

            }
        }
        .colors {
            position: relative;
          display: flex;
          justify-content: space-between;
          .opacity-title {
            position: absolute;
            top: 10px;
            left: 506px;
            font-size: 21px;
        }
          .color-selections {
            .color-selection {
              display: flex;

              .color-title {
                font-size: 20px;
                width: 90px;
                margin-right: 1px;
                text-align: right;
                position: relative;
                top: 2px;
              }
              .arrow-hidden {
                width: 30px;
                height: 30px;
              }
              .display-arrow {
                display: flex;
                border-top: 4px solid black;
                width: 30px;
                height: 30px;
                position: relative;
                top: 16px;
                left: 3px;
                span {
                  position: absolute;
                  top: -14px;
                  left: -7px;
                  display: block;
                  width: 20px;
                  height: 21px;
                  color: green;
                  border-top: 4px solid black;
                  border-right: 4px solid black;
                  transform: rotate(45deg) skew(-10deg, -10deg);
                }
              }
              input[type="text"] {
                font-size: 17px;
                padding: 1px 3px;
                width: 85px;
                border: 1px solid black;
                margin-left: 0.5rem;
                margin-bottom: 3px;
                &.invalid {
                    background-color: #fdd;
                    border: 1px solid red !important;
                }
              }
            }
          }
          .opacity-settings {
              padding-top: 14px;
            .opacity {
                height: 34px;
            
                
                input[type="range"] {
                    width: 60px;
                    height: 23px;
                    margin-left: 6px;
                    box-shadow: 0 0 1px 1px rgba(0,0,0,0.1);
                }
                span {
                    display: inline-block;
                    width: 40px;
                    font-size: 18px;
                    text-align: right;
                    position: relative;
                    left: -8px;
                    top: -4px;
                }
            }
        }
          div {
            margin: auto;
          }
          input[type="text"] {
            margin-top: 8px;
     
            padding: 0 5px;
            width: 100px;
            border: 1px solid black;
            letter-spacing: 0.5px;
          }
        }
        .orientation {
          display: flex;
        }

        .position {
            display: flex;
            .control-position {
                margin-left: 10px;
                input[type="range"] {
                    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
                }
                span {
                    position: relative;
                    top: -3px;
                }
            }
        }

        input[type="number"], input[type="text"] {
          font-size: 1.4rem;
          padding: 0 5px;
          width: 80px;
          border: 1px solid black;
          margin-left: 0.5rem;
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          position: relative;
          top: 3px;
          margin-left: 1rem;
        }
        .hexCenterDotSize {
          input[type="number"] {
            width: 80px;
          }
        }
        .hexNumberFontSize {
          input[type="number"] {
            width: 60px;
          }
        }
        .hex-line-width {
          input[type="text"] {
            width: 60px;
          }
        }
        span {
          margin-left: 0.5rem;
          font-size: 1.2rem;
        }

        .action-buttons {
            display: flex;
            justify-content: space-around;
            border-top: 1px solid #aaa;
            position: relative;
            padding: 14px 110px 9px 110px;
            top: 10px;
          button {
            padding:    8px 14px 7px 14px;
            font-size: 20px;
            border-radius: 10px;
            box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
            color: white;
            font-weight: bold;
            &.submit {
                border: 1px solid #68d;
                background: linear-gradient(to bottom, #2368b2 0%, #207cca 27%, #2681cc 56%, #0d6fd8 88%);
            }
            &.reset {
                border: 1px solid #f62;
                background: linear-gradient(to bottom, #FF4500 0%, #FC6520 27%, #FC6520 56%, #FF4500 88%);
                &:hover {
                    color: yellow;
                    background: linear-gradient(to bottom, #eF4500 0%, #eC6520 27%, #eC6520 56%, #eF4500 88%);
                }

            }

            cursor: pointer;
            &:hover {
                color: yellow;
                background: linear-gradient(to bottom, #115f91 0%, #2177b1 27%, #267bb2 56%, #0d6acd 88%);
            }
            &:active {
                opacity: 0.8;
                transform: scale(0.97) translate(1px, -1px);
                &.reset {
                    background: linear-gradient(to bottom, #bF2500 0%, #bC6520 27%, #bC4520 56%, #bF2500 88%);
                    transform: scale(0.99) translate(0, 0.01rem);
                    box-shadow: 0 0 1px 0 rgba(0,0,0,0.4), inset 1px 1px 1px 2px rgba(0,0,0,0.5), inset -1px -1px 1px 2px rgba(0,0,0,0.7);
                    text-shadow: 0 0 2px yellow;
                }
            }
            &.remove {
                border: 1px solid #d55;
                background: linear-gradient(to bottom, #db0e00 0%, #ce1100 10%, #ff1a00 53%, #ce0600 75%);
                &:hover {
                    color: yellow;
                    background: linear-gradient(to bottom, #cb0e00 0%, #be1100 10%, #ef1a00 53%, #be0600 75%);
                }
            }
            &:focus {
                outline: none;
            }
          }
        }
        .invalid {
          input[type="number"], input[type="text"] {
            background-color: #fdd;
            border: 1px solid red !important;
          }
        }
      }
    }
  }
}


button::-moz-focus-inner {
    border: 0;
  }  
