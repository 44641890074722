.path-reducer {
  text-align: center;
  margin-top: 1%;
  .title {
    font-size: 40px;
    font-weight: bold;
  }
  .centered {
    border: 1px solid black;
    width: 1500px;
    display: inline-block;
    margin: 0 auto;
    .enter-path {
      textarea {
        width: 90%;
        height: 500px;
        padding: 5px;
      }
    }
    .action-button {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      font-size: 20px;
      div {
          margin-left: 10px;
      }
      input[type="text"] {
          width: 30px;
          font-size: 20px;
      }
      input[type="submit"], input[type="button"] {
        margin-left: 15px;
        font-size: 20px;
      }
    }
    div.text-title {
      margin: 10px 0 6px 10px;
      font-size: 20px;
      text-align: left;
    }
    .reduced-path {
      text-align: center;
      .path-area {
        background-color: #eee;
        width: 90%;
        display: inline-block;
        min-height: 300px;
        border: 1px solid black;
        margin-bottom: 15px;
        text-align: left;
        padding: 5px;
      }
    }
  }
}
