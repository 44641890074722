.layer {
  background-color: white;
  color: black;
  font-size: 95%;
  border: 2px solid #000;
  border-top: 5px solid #000;
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  &.indicate-layer {
      background-color: #99aaff;
      .layer-header {
          background-color: #3333ff;
      }
  }
  .inputs-flex {
    display: flex;
    flex-direction: column;
    margin: 0 0 0.6% 0;
    padding: 1.1% 1.3% 0.1% 1.3%;
    font-size: 90%;
    flex-wrap: nowrap;
    div {
      display: flex;
      padding: 0.35% 0.1% 0.1% 0;
      flex-wrap: nowrap;
      .input-container {
        padding: 0.35% 0.1% 0.1% 3%;
        cursor: default;
        @media screen and (max-width: 1200px) {
          padding: 0.15% 0.05% 0.05% 0;
        }
        label {
          cursor: pointer;
        }
        span:first-child {
          margin-right: 3px;
        }
        input[type="text"] {
          cursor: text;
          &.color-focused {
            box-shadow: 0 0 3px 1px red;
            border: 1px solid red;
          }
        }
        input[type="radio"] {
          cursor: pointer;
        }
        input[type="checkbox"] {
          cursor: pointer;

          position: relative;
          top: 0px;

          @-moz-document url-prefix() {
            transform: scale(1.5);
            position: relative;
            top: 20%;
          }
        }
      }
      .input-container:first-child {
        padding: 0.35% 0.1% 0.1% 0;
        flex-basis: initial;
        @media screen and (max-width: 1200px) {
          padding: 0.15% 0.05% 0.05% 0;
        }
      }
    }
  }

  .layer-header {
    background-color: #001155;
    color: white;
    font-size: 80%;
    padding: 1px 1px 2px 6px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space: nowrap;
    .indicate-layer {
        background-color: yellow;
        color: black;
        top: -1px;
    }
    .layer-contents-hidden {
        opacity: 0.6;
    }
    .contents-toggle {
        display: inline-block;
        text-align: center;
        width: 24px;
        margin-right: 10px;
        height: 23px;
        font-size: 20px;
        font-family: "Goblin One";
        font-weight: bold;
        background-color: #004a99;
        cursor: pointer;
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; 
        &.toggle2 {
            background-color: #444;
            position: relative;
            width: 23px;
            height: 18px;
            padding-right: 1px;
            padding-bottom: 4px;
        }
    }
    &.edit {
      justify-content: flex-start;
      button {
        margin-left: 30px;
      }
    }
    .smaller {
      height: 20px;
      margin: 0 10px 0px 0;
      position: relative;
      top: -2px;
    }
    .layer-type-text {
      color: #aaa;
      margin-right: 15px;
      position: relative;
      top: -2px;
      font-size: 80%;
    }
  }


}
