.layer-drag-manager {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;

  .layer-drag-manager-layers {
    margin-top: 5%;
    border: 1px solid gray;
    background-color: gray;
    .header-names {
      display: flex;
      font-size: 80%;
      background-color: #029;
      color: white;
      .header-name-eye {
        width: 50px;
      }
      .header-name-name {
        width: 150px;
      }
      .header-name-elements {
        width: 140px;
      }
      .header-name-remove {
        width: 100px;
        background-color: #fda;
      }
    }
    .drop-slot {
      height: 3px;
      background-color: black;
      color: white;
      transition-duration: 100ms;
      width: 100%;
      &.is-over {
        height: 16px;
        background-color: red;
      }
    }
    .under-drop-slot {
        height: 1px;
        background-color: black;
        width: 100%;
    }
    .layer-drag-manager-layer {
      display: flex;
      background-color: white;
      margin-top: 1px;
      flex-wrap: wrap;

      .layer-eye {
        width: 30px;
        height: 20px;
        text-align: center;
        background-color: #cdf;
        cursor: pointer;
        &.hidden {
          background-color: #444;
        }
      }
      .layer-drag-manager-layer-name {
        padding-top: 1px;
        margin-left: 4px;
        flex-basis: calc(100% - 35px);
        white-space: nowrap;
        flex-shrink: 0;
        vertical-align: baseline;
        display: flex;
        justify-content: space-between;
        span {
          margin: 0 4px 0 4px;
          cursor: pointer;
        }
      }
      .layer-elements {
        position: relative;
        top: 1px;
        flex-basis: 48%;
        white-space: nowrap;
        flex-shrink: 0;
        border-left: 1px solid gray;
        padding-left: 4px;
        span:after {
          content: ",";
        }
        span:last-child:after {
          content: "";
        }
      }
      .layer-remove {
        border-left: 1px solid gray;
        padding-left: 4px;
        span {
          display: inline-block;
          font-family: "Rubik";
          font-size: 15px;
          padding-top: 2px;
          color: red;
          transform: scaleX(1.5);
          font-weight: bold;
        }
        margin-left: auto;
        flex-basis: 14px;
      }
    }
  }
}
