.tab-manager {
  .tabName-tabs {
    display: flex;
    background-color: #015;

    .tabName-tab {
      margin-top: 2px;
      &:not(:first-child) {
        border-left: 1px solid black;
      }
      border-top: 1px solid black;
      border-right: 1px solid black;
      font-size: 87%;
      //margin: 0 0.1rem 0 0.2rem;
      border-top-left-radius: 2%;
      border-top-right-radius: 2%;
      background-color: #666;
      color: #eee;
      cursor: pointer;
      &.active {
        background-color: #f5f5f5;
        color: black;
      }
      span {
        margin: 0 0.5rem 0 0.5rem;
      }
    }
  }
}
