.hexmap {
    position: relative;
    .svg-container {
        position: absolute;
        top: 0;
        left: 0;
    }
}
#thesvg {
    text-decoration: none !important;
}
