@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Anton');
@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Staatliches');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Racing+Sans+One');
@import url('https://fonts.googleapis.com/css?family=Squada+One');
@import url('https://fonts.googleapis.com/css?family=Francois+One');
@import url('https://fonts.googleapis.com/css?family=Fjalla+One');
@import url('https://fonts.googleapis.com/css?family=Rubik');
@import url('https://fonts.googleapis.com/css?family=Baloo+Bhai');
@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Germania+One');
@import url('https://fonts.googleapis.com/css?family=Goblin+One');
@import url('https://fonts.googleapis.com/css?family=Quantico');
@import url('https://fonts.googleapis.com/css?family=Ramabhadra');
@import url('https://fonts.googleapis.com/css?family=Kreon');
@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import url('https://fonts.googleapis.com/css?family=Overpass');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Condensed');
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Arial", sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
