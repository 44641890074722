.counter-design {
    position: relative;
    background-color: #00ffff;
    width: 100%;
    padding-top: 100%;
    .display-svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      user-select: none;
    }
  }