.counter-loaded-saved-info {
    background: #001155;
    font-size: 70%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #aaa;
    padding: 2px 0 1px 0;
    .loaded-saved {
        color: white;
        text-transform: capitalize;
        font-size: 110%;
    }
    .data-part {
        color: yellow;
        text-transform: none;
        &.date {
            position: relative;
            top: 1px;
        }
    }
}